<template>
    <div class="image-drag-sort">
        <ul class="luther-dragsort-list">
            <TransitionGroup>
                <li
                    v-for="(item, index) of fileList"
                    :key="item"
                    class="luther-dragsort-item"
                    :draggable="true"
                    @dragstart="onDragstart($event, index)"
                    @dragover.prevent="onDragOver"
                    @drop="onDrop($event, index)"
                >
                    <img
                        :src="item.url || item"
                        alt=""
                        class="luther-drasort__item-thumbnail"
                    />
                    <span class="luther-drasort__item-actions">
                        <span class="luther-drasort__item-preview">
                            <el-icon @click="onPreview(item)"
                                ><ZoomIn
                            /></el-icon>
                        </span>
                        <span class="luther-drasort__item-download">
                            <el-icon @click="onDownload(item, downloadName)"
                                ><Download
                            /></el-icon>
                        </span>
                        <span class="luther-drasort__item-delete">
                            <el-icon @click="onDelete(item)"
                                ><Delete
                            /></el-icon>
                        </span>
                    </span>
                </li>
                <el-upload
                    ref="lutherElUpload"
                    :method="method"
                    :accept="accept"
                    :show-file-list="drag"
                    v-model:file-list="fileList"
                    auto-upload
                    :action="action"
                    :list-type="listType"
                    :before-upload="beforeUpload"
                    :on-success="onSuccess"
                >
                    <el-icon><Plus /></el-icon>
                </el-upload>
            </TransitionGroup>
        </ul>
    </div>
    <el-dialog
        v-model="dialogVisible"
        :show-close="false"
        width="auto"
        style="display: inline-flex; justify-content: center"
    >
        <div style="height: 450px">
            <img
                class="luther-drasort__item-thumbnail"
                :src="dialogImageUrl"
                alt="Preview Image"
            />
        </div>
    </el-dialog>
</template>

<script setup>
import { defineProps, ref, defineModel } from 'vue';
import { ElUpload, ElIcon, ElMessage, ElDialog } from 'element-plus';
import { Plus, Delete, ZoomIn, Download } from '@element-plus/icons-vue';
const fileList = defineModel('fileList');
const props = defineProps({
    action: String,
    method: {
        type: String,
        default: 'POST',
    },
    accept: {
        type: String,
        default: 'image/jpeg,image/png,image/gif',
    },
    drag: Boolean,
    listType: {
        type: String,
        default: 'picture-card',
    },
    downloadName: {
        type: String,
        default: 'image',
    },
    beforeUpload: Function,
});
const lutherElUpload = ref();
const draggedIndex = ref(0);
const dialogVisible = ref(false);
const dialogImageUrl = ref('');
const onSuccess = (response, file) => {
    if (response.code == 200) {
        file.url = response.url;
        ElMessage.success('图片上传成功');
    } else {
        ElMessage.error('图片上传失败');
        fileList.value.pop();
    }
    file.url = response.url;
};
const urlDownload = (url, name) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        const _url = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.download = name;
        a.href = _url;
        a.click();
    };
    image.src = url;
};
const onDownload = (file, fileName) => {
    urlDownload(file.url, fileName);
};
const onPreview = (file) => {
    dialogImageUrl.value = file.url;
    dialogVisible.value = true;
};
const onDelete = (file) => {
    fetch(props.action, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'X-Url-Header': file.url,
        },
        mode: 'cors',
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.code == 200) {
                ElMessage.success('删除成功');
                lutherElUpload.value.handleRemove(file);
            } else {
                ElMessage.error('删除失败');
            }
        })
        .catch(() => {
            ElMessage.error('删除失败');
        });
};
const onDragstart = (e, index) => {
    draggedIndex.value = index;
};
const onDragOver = () => {
    // e;
};
const onDrop = (e, index) => {
    fileList.value.splice(
        index,
        0,
        fileList.value.splice(draggedIndex.value, 1)[0]
    );
};
</script>

<style lang="scss" scoped>
.luther-dragsort-list {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
}
.luther-dragsort-item {
    transition: transform 1s ease;
    flex-shrink: 0;
    width: 148px;
    height: 148px;
    overflow: hidden;
    background-color: var(--el-fill-color-blank);
    border: 1px solid var(--el-border-color);
    border-radius: 6px;
    box-sizing: border-box;
    margin: 0 8px 8px 0;
    padding: 0;
    font-size: 14px;
    color: var(--el-text-color-regular);
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.luther-drasort__item-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
}
.luther-drasort__item-actions:hover {
    opacity: 1;
    span {
        display: inline-flex;
    }
}
.luther-drasort__item-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: var(--el-overlay-color-lighter);
    transition: opacity var(--el-transition-duration);
    span {
        display: none;
        cursor: pointer;
    }
    .luther-drasort__item-delete,
    .luther-drasort__item-download {
        margin-left: 16px;
    }
}
</style>

<template>
  <el-row :gutter="20">
    <el-col
      :md="12"
      :span="24"
    >
      <h3 class="title">商品信息</h3>
      <div class="text-info">
        <el-form :model="info">
          <el-form-item
            label="产品属类"
            prop="type1"
          >
            <el-radio-group
              v-model="info.type1"
              disabled
            >
              <el-radio value="toner">Toner</el-radio>
              <el-radio value="spare-parts">Spare Parts</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="产品类型"
            prop="type2"
          >
            <el-select
              v-model="info.type2"
              placeholder="Select"
              size="large"
              style="width: 240px"
            >
              <el-option
                v-for="item in type2Options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="品牌"
            prop="brand"
          >
            <el-select
              v-model="info.brand"
              placeholder="Select"
              size="large"
              style="width: 240px"
            >
              <el-option
                label="Konica Minolta"
                value="Konica Minolta"
              />
              <el-option
                label="Ricoh"
                value="Ricoh"
              />
              <el-option
                label="Canon"
                value="Canon"
              />
              <el-option
                label="Xerox"
                value="Xerox"
              />
              <el-option
                label="Kyocera"
                value="Kyocera"
              />
              <el-option
                label="Sharp"
                value="Sharp"
              />
              <el-option
                label="Toshiba"
                value="Toshiba"
              />
              <el-option
                :disabled="info.type1 != 'toner'"
                label="HP"
                value="HP"
              />
              <el-option
                :disabled="info.type1 != 'toner'"
                label="Brother"
                value="Brother"
              />
              <el-option
                :disabled="info.type1 != 'toner'"
                label="Samsung"
                value="Samsung"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="主题"
            prop="theme"
          >
            <el-input
              v-model="info.theme"
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table
          border
          :data="info.detail"
          style="width: 100%"
        >
          <el-table-column
            label="显示字段"
            prop="display"
          >
            <template #default="{ row }">
              <el-input
                :readonly="row.required"
                v-model="row.display"
                placeholder="Place Field"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="显示的值"
            prop="value"
          >
            <template #default="{ row }">
              <el-input
                v-model="row.value"
                placeholder="Place value"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200"
            class-name="operate"
          >
            <template #default="scope">
              <el-button
                :disabled="scope.row.required"
                @click="deleteRow(scope.$index)"
                >删除</el-button
              >
              <div :class="{sort: true, disabled: scope.$index == 0}" @click="handleSort(scope, 'top')">
                <el-icon><Top /></el-icon>
              </div>
              <div :class="{sort: true, disabled: scope.$index == info.detail.length - 1}" @click="handleSort(scope, 'bottom')">
                <el-icon><Bottom /></el-icon>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          style="width: 100%"
          @click="addItem"
          >添加</el-button
        >
      </div>
    </el-col>
    <el-col
      :md="12"
      :span="24"
    >
      <h3 class="title">图片信息</h3>
      <div class="image-info">
        <ImageDragSort 
          v-model:file-list="info.coverList" 
          action="/docu/upload" 
          :before-upload="handleBeforeUpload"
        />
        <!-- <el-upload
          v-model:file-list="info.coverList"
          method="post"
          accept="image/jpeg,image/png,image/gif"
          auto-upload
          action="/docu/upload"
          list-type="picture-card"
          :on-preview="handlePrview"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="handleError"
        >
          <el-icon><Plus /></el-icon>
        </el-upload> -->
      </div>
    </el-col>
  </el-row>
  <!-- <el-dialog v-model="dialogVisible">
    <div class="dialog-image">
      <img w-full :src="dialogImageUrl" fit="cover" alt="Preview Image" />
    </div>
  </el-dialog> -->
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import {
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElRadioGroup,
  ElRadio,
  ElSelect,
  ElOption,
  ElTable,
  ElTableColumn,
  ElButton,
  ElMessage,
  ElIcon,
} from "element-plus";
import { Top, Bottom } from "@element-plus/icons-vue";
import ImageDragSort from "@/components/ImageDragSort.vue";
const props = defineProps({
    data: {
        type: Object,
        required: true,
        default: () => ({})
    }
});
const info = ref(props.data);
const tonerOptions = [
  {
    label: "Toner Cartridge",
    value: "Toner Cartridge",
  },
  {
    label: "Toner Powder",
    value: "Toner Powder",
  },
];
const sparePartsOptions = [
  {
    label: "Drum Units",
    value: "Drum Units",
  },
  {
    label: "Developer Units",
    value: "Developer Units",
  },
  {
    label: "Fuser Units",
    value: "Fuser Units",
  },
  {
    label: "OPC Drum",
    value: "OPC Drum",
  },
  {
    label: "Cleaning Blade",
    value: "Cleaning Blade",
  },
  {
    label: "Other Parts",
    value: "Other Parts",
  }
];
const type2Options = ref(info.value=='toner'?tonerOptions:sparePartsOptions);
// const dialogVisible = ref(false);
// const dialogImageUrl = ref('');
watch(props, () => {
  info.value = props.data;
  type2Options.value = info.value=='toner'?tonerOptions:sparePartsOptions;
});
const deleteRow = (index) => {
  info.value.detail.splice(index, 1);
  ElMessage.success("删除成功");
};
const addItem = () => {
  info.value.detail.push({
    display: "",
    value: "",
  });
  ElMessage.success("添加成功");
};
const handleBeforeUpload = (uploadFile) => {
  const type = uploadFile.type;
  if (type !== "image/jpeg" && type !== "image/png" && type !== "image/gif") {
    ElMessage.warning("请选择正确的文件类型, 当前的类型为" + type);
    return false;
  }
  const size = (uploadFile.size / 1024 / 1024).toFixed(2);
  if (size > 5) {
    ElMessage.warning("当前的文件大小为" + size + "MB, 最大不超过 5MB");
    return false;
  }
  const img = new Image();
  let _URL = window.URL || window.webkitURL;
  return new Promise((resolve, reject) => {
    img.onload = () => {
      if (img.width / img.height >= 0.90 && img.width / img.height <= 1.5) {
        resolve();
      } else {
        ElMessage.info(
          `当前的图片宽高比为 ${(img.width / img.height).toFixed(
            2
          )}, 宽高比的范围是 0.950 ~ 1.10`
        );
        reject();
      }
    };
    img.src = _URL.createObjectURL(uploadFile);
  });
};
// const handleRemove = () => {
//   ElMessage.success("图片删除成功");
// };
// const handleSuccess = (response) => {
//   if (response.code == 200) {
//     ElMessage.success("图片上传成功");
//   } else {
//     ElMessage.error("图片上传失败");
//     info.value.coverList.value.pop();
//   }
// };
// const handleError = (err) => {
//   ElMessage.error("图片上传失败", err);
// };
const handleSort = (data, direction) => {
  let currentRow = info.value.detail.splice(data.$index, 1);
  if (direction == 'top') {
    info.value.detail.splice(data.$index - 1, 0, ...currentRow)
  } else {
    info.value.detail.splice(data.$index + 1, 0, ...currentRow)
  }
}
// const handlePrview = (uploadFile) => {
//   dialogImageUrl.value = uploadFile.url
//   dialogVisible.value = true
// }
</script>
<style scoped>
.text-info,
.image-info {
  min-height: 200px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #aaa;
  border-radius: 10px;
}
.title {
  line-height: 48px;
}
:deep(.operate .cell) {
  display: flex;
  justify-content: space-around;
}
.sort {
  height: 32px;
  width: 30px;
  border-radius: 5px;
  font-size: 20px;
  background-color: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.disabled {
  opacity: 0.7;
  cursor: no-drop;
  pointer-events: none;
}
.dialog-image {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center
}
.dialog-image img {
  height: 400px;
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import LayoutView from '../views/LayoutView.vue';
import ErrorView from '../views/ErrorView.vue';
import { RouteResolve } from './RouteResolver';

const resolve = new RouteResolve();
const routes = [
  {
    path: '/',
    component: LayoutView
  },
  {
    path: '/home',
    name: 'home',
    component: LayoutView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/:pathMatch(.*)',
    component: ErrorView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.path == '/login') {
    next();
  } else {
    resolve.resolve().then((res) => {
      if (res) {
        next()
      }
    })
  }
})

export default router

import { getCurrentUser } from "@/api/users";
export class RouteResolve {
    constructor() {
        this.isGetUserSession = false;
    }
    resolve() {
        return new Promise((resolve) => {
            if (this.isGetUserSession) {
                resolve(true)
            } else {
                getCurrentUser().then(res => {
                    if (res.code == 200) {
                        this.isGetUserSession = true
                        resolve(true)
                    } else {
                        this.isGetUserSession = false
                    }
                }).catch(() => {
                    this.isGetUserSession = false;
                });
            }
        });
    }
}
export default {};